import React, {Component} from 'react';
import './GroupPanel.css';
import {ThreeBarsIcon, XIcon} from '@primer/octicons-react';
import jsonGroups from '../../assets/data/dete-groups';

const textes = {
    'groupes': {
        'fr': 'Groupes',
        'de': 'Gruppen',
        'it': 'Gruppi',
        'en': 'Groups'
    },
    'groupSelect': {
        'fr': 'Choisissez un groupe',
        'de': 'Wählen Sie eine Tiergruppe aus',
        'it': 'Scegliere un gruppo'
    }
};

class GroupPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openClass: props.groupe ? 'left-panel left-panel-close' : 'left-panel',
            iconClass: 'left-panel-icon',
            panelHidden: !!props.groupe,
            nuesp: props.nuesp,
            groupes: jsonGroups,
            groupe: props.groupe,
            locale: props.locale
        };

        this.openPanel = this.openPanel.bind(this);
        this.closePanel = this.closePanel.bind(this);
    }

    openPanel(e) {
        this.setState({
            openClass: 'left-panel',
            panelHidden: !this.state.panelHidden
        })
    }

    closePanel(e) {
        this.setState({
            openClass: 'left-panel left-panel-close',
            panelHidden: !this.state.panelHidden
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.locale !== nextProps.locale) {
            this.setState({
                locale: nextProps.locale
            });
        }

        return true;
    }

    render() {

        const {locale} = this.state;

        return (
            <div className={this.state.openClass}>
                <button onClick={this.openPanel} className="button-link left-panel-open-handle"
                   hidden={!this.state.panelHidden}>
                    <ThreeBarsIcon size='medium' verticalAlign='middle' className={this.state.iconClass}/>
                    <h2 className="left-panel-title">{textes.groupes[locale]}</h2>
                </button>
                <button hidden={this.state.panelHidden} onClick={this.closePanel}
                   className="button-link left-panel-close-handle">
                    <XIcon size='medium' verticalAlign='middle' className="left-panel-icon"/>
                </button>
                <h2>{textes.groupes[locale]}</h2>
                <p>{textes['groupSelect'][this.state.locale]}</p>
                <div className="list-group" id="groupList" role="tablist">
                    {
                        this.state.groupes.map(groupe => {
                            let logoLink = "https://lepus.infofauna.ch/taxonomic-key-web/data/" + groupe.short + "/" + groupe.logo;
                            return (
                                    <a key={groupe.short} data-key={groupe.short}
                                       className={this.state.groupe === groupe.short ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"}
                                       href={groupe.short}><img src={logoLink} className="logo" alt={"logo" + groupe.short}/>&nbsp;&nbsp;{groupe['name_' + locale]}</a>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default GroupPanel;
