import React, {Component} from 'react';
import './LangPanel.css';
import LinkerComponent from "../linkerComponent/LinkerComponent";

const langs = ['fr', 'de', 'it'];

class LangPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locale: props.locale
        };

        this.langChange = this.langChange.bind(this);
    }

    langChange(e) {
        localStorage.setItem('if.key.lang', e.target.value);
        this.setState({locale: e.target.value});
        this.props.callbackFromParent(e.target.value);
    }

    render() {
        return (
            <div>
                <LinkerComponent locale={this.state.locale}/>
                <div className="lang-selector">
                    <select onChange={this.langChange} value={this.state.locale}>
                        {langs.map((item) => {
                            return (
                                <option key={item} value={item}>{item}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
        )
    }
}

export default LangPanel;
