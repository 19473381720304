import React, {Component} from 'react';
import "yet-another-react-lightbox/styles.css";
import './QuestionPanel.css';
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import {Button, Modal} from "react-bootstrap";
import nmbe from '../../assets/images/NMBE_Logo.png';
import eawag from '../../assets/images/logo_eawag_unizh.png';
import ReactGA from "react-ga";

ReactGA.initialize('UA-268645-7');

const textes = {
    "oui": {
        "fr": "Oui",
        "de": "Ja",
        "it": "Si"
    },
    "non": {
        "fr": "Non",
        "de": "Nein",
        "it": "No"
    },
    "nonvisible": {
        "fr": "Critère non visible",
        "de": "Unsicher oder Merkmal nicht gesehen",
        "it": "Criterio non visibile"
    },
    "recommencer": {
        "fr": "Recommencer",
        "de": "Neu beginnen",
        "it": "Ricominciare"
    },
    "question": {
        "fr": "Question",
        "de": "Frage",
        "it": "Domanda"
    },
    "critNonDet": {
        "fr": "Les critères que vous avez choisis sont insuffisants pour déterminer l'espèce avec certitude. Voici la liste des espèces possibles. Cliquez sur une espèces pour voir les critères d'identification ou recommencez la détermination.",
        "de": "Die Kriterien die Sie gewählt haben reichen nicht aus um die Art sicher zu bestimmen. Untenstehende Liste zeigt Ihnen die möglichen Arten. Klicken Sie auf eine Art, um die Merkmale anzuzeigen, oder beginnen Sie die Bestimmung erneut.",
        "it": "I criteri scelti sono insufficenti per derminare la specie con certitudine. Ecco la lista delle specie possibile. Clicca su una specie per vedere i criteri d’identificazione o rincommincate la determinazione."
    },
    "commencercle": {
        "fr": "Commencer la clé",
        "de": "Bestimmung beginnen",
        "it": "Inizia la chiave"
    },
    "listefiches": {
        "fr": "Fiches des espèces",
        "de": "Artenliste",
        "it": ""
    },
    "aLaCle": {
        "fr": "Aller à la clé",
        "de": "Bestimmungsschlüssel",
        "it": ""
    }
};

class QuestionPanel extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            locale: props.groupe === 'moll' ? 'de' : props.locale,
            groupe: props.groupe,
            groupInfo: null,
            data: null,
            selection: [],
            currentQuestion: null,
            currentQuestionImage: null,
            currentQuestionValue: null,
            getTaxons: null,
            getQuestions: null,
            dataMatrix: null,
            getQuestionImages: null,
            currentBestQuestion: null,
            questionForm: null,
            isFinished: false,
            resultTaxon: null,
            photoIndex: 0,
            isOpen: false,
            images: [],
            modalOpen: false,
            mainText: null,
            launchText: props.groupe === 'moll' || props.groupe === 'amphipoda',
            listFiches: false,
            introText: true,
            groupText: false,
            multiQuestions: false,
            singleQuestion: false,
            speciesList: false,
            multiResponses: false,
            singleResponse: false,
            taxonList: null
        };

        fetch("intro_" + props.locale + ".html")
            .then(r => r.text())
            .then(d => {
                this.setState({
                    mainText: d
                });
            });

        if (props.groupe) {
            fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + props.groupe + "/taxons.json")
                .then(r => r.json())
                .then(d => {
                    this.setState({
                        taxonList: d
                    })
                });
            ReactGA.pageview('/key-react/' + props.groupe);
            fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + props.groupe + "/infos.json")
                .then(r => r.json())
                .then(d => {
                    let arr = new Array(d.multi_names_fr.length);
                    arr.fill(false, 0, d.multi_names_fr.length);

                    this.setState({
                        groupInfo: d,
                        singleQuestion: (d.multi === 'none'),
                        groupe: props.groupe,
                        openClass: 'panel panel-close',
                        panelDisabled: false,
                        selection: arr,
                        data: null,
                        currentQuestion: null,
                        currentQuestionImage: null,
                        currentQuestionValue: null,
                        getTaxons: null,
                        getQuestions: null,
                        dataMatrix: null,
                        getQuestionImages: null,
                        currentBestQuestion: null,
                        questionForm: null,
                        isFinished: false,
                        resultTaxon: null
                    });

                    if (props.groupe === 'moll' || props.groupe === 'amphipoda') {
                        this.goTo(null,'groupText');
                    } else if (d.multi === 'none') {
                        this.selectFirstQuestion();
                        this.goTo(null, 'singleQuestion');
                    } else {
                        this.goTo(null, 'multiQuestions')
                    }
                })
        }

        this.repeatGroup = this.repeatGroup.bind(this);
        this.goTo = this.goTo.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        if (this.props.groupe !== nextProps.groupe) {
            this._isMounted = true;

            fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + nextProps.groupe + "/infos.json")
                .then(r => r.json())
                .then(d => {
                    if (this._isMounted) {

                        let arr = new Array(d.multi_names_fr.length);
                        arr.fill(false, 0, d.multi_names_fr.length);

                        this.setState({
                            groupInfo: d,
                            singleQuestion: (d.multi === 'none'),
                            groupe: nextProps.groupe,
                            openClass: 'panel panel-close',
                            panelDisabled: false,
                            selection: arr,
                            data: null,
                            currentQuestion: null,
                            currentQuestionImage: null,
                            currentQuestionValue: null,
                            getTaxons: null,
                            getQuestions: null,
                            dataMatrix: null,
                            getQuestionImages: null,
                            currentBestQuestion: null,
                            questionForm: null,
                            isFinished: false,
                            resultTaxon: null,
                            modalShow: false,
                            groupText: nextProps.groupe === 'moll',
                            introText: false
                        });

                        if (d.multi === 'none') {
                            this.selectFirstQuestion();
                            this.goTo(null, 'singleQuestion');
                        } else {
                            this.goTo(null, 'multiQuestions')
                        }
                    }

                })
        }

        if (this.props.locale !== nextProps.locale) {

            fetch("intro_" + nextProps.locale + ".html")
                .then(r => r.text())
                .then(d => {
                    this.setState({
                        locale: nextProps.locale,
                        mainText: d
                    });
                });

        }

        return true;
    }

    clone(obj) {
        if (null == obj || "object" != typeof obj) return obj;

        if (obj instanceof Date) {
            let copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        if (obj instanceof Array) {
            let copy = [];
            for (let i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.clone(obj[i]);
            }
            return copy;
        }

        if (obj instanceof Object) {
            let copy = {};
            for (let attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = this.clone(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }

    transpose(a) {
        let w = a.length || 0;
        let h = a[0] instanceof Array ? a[0].length : 0;
        if (h === 0 || w === 0) {
            return [];
        }
        let i, j, t = [];
        for (i = 0; i < h; i++) {
            t[i] = [];
            for (j = 0; j < w; j++) {
                t[i][j] = a[j][i];
            }
        }
        return t;
    }

    isFinished() {
        let h, i;
        let r = false;
        let dataMatrix = this.state.dataMatrix;

        let tm = this.transpose(dataMatrix);
        let th = 0 + tm.length;
        for (h = 0; h < tm.length; h++) {
            if (!!tm[h].reduce(function (a, b) {
                return (a === b) ? a : NaN;
            })) {
                th--;
            } else {
                let ts = false;
                for (i = 0; i < tm[h].length; i++) {
                    if (tm[h][i] === "3") {
                        ts = true;
                    }
                }
                if (ts) {
                    th--;
                }
            }
        }
        if (th === 0) {
            r = true;
        }

        return r;
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    selectNextQuestion(e, r) {
        let h;
        let q = this.state.currentQuestionValue;
        let dataMatrix = this.state.dataMatrix;
        let getQuestions = this.state.getQuestions;
        let getQuestionImages = this.state.getQuestionImages;
        let getTaxons = this.state.getTaxons;

        if (r === 3) {
            for (h = 0; h < dataMatrix.length; h++) {
                dataMatrix[h].splice(q, 1);
            }
            getQuestions.splice(q, 1);
            getQuestionImages.splice(q, 1);
        } else {
            for (h = 0; h < dataMatrix.length; h++) {
                if (Number(dataMatrix[h][q]) === r || dataMatrix[h][q] === '2') {
                    dataMatrix[h].splice(q, 1);
                } else {
                    dataMatrix.splice(h, 1);
                    getTaxons.splice(h, 1);
                    h--;
                }
            }
            getQuestions.splice(q, 1);
            getQuestionImages.splice(q, 1);
        }
        if (this.isFinished()) {

            if (getTaxons.length === 1) {
                fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/taxons.json")
                    .then(s => s.json())
                    .then(taxons => {
                        taxons = taxons.sort((a, b) => {
                            if (a.name < b.name) return -1;
                            else if (a.name > b.name) return 1;
                            else return 0;
                        });
                        let result;
                        taxons.forEach(function (obj) {
                            if (obj.nuesp == getTaxons[0][1][0]) {
                                result = obj;
                            }
                        });
                        result["description_fr"] = result["description_fr"].replace(new RegExp('<source src="', 'g'), '<source src="https://lepus.infofauna.ch/taxonomic-key-web/data/' + this.state.groupe + '/r_images/');
                        result["description_de"] = result["description_de"].replace(new RegExp('<source src="', 'g'), '<source src="https://lepus.infofauna.ch/taxonomic-key-web/data/' + this.state.groupe + '/r_images/');
                        result["description_it"] = result["description_it"].replace(new RegExp('<source src="', 'g'), '<source src="https://lepus.infofauna.ch/taxonomic-key-web/data/' + this.state.groupe + '/r_images/');
                        let images = [];
                        if (result['leadimg']) {
                            images.push("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/r_images/' + result['leadimg'][0].filename);
                        }
                        result['imgs'].forEach(img => {
                            images.push("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/r_images/' + img.filename);
                        });
                        this.setState({
                            resultTaxon: result,
                            images: images,
                            singleQuestion: false
                        })
                    })
            } else {
                fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/taxons.json")
                    .then(s => s.json())
                    .then(taxons => {
                        taxons = taxons.sort((a, b) => {
                            if (a.name < b.name) return -1;
                            else if (a.name > b.name) return 1;
                            else return 0;
                        });
                        let result = [];
                        let selectedTaxons = [];
                        getTaxons.forEach(taxon => {
                            selectedTaxons.push(taxon[1][0])
                        });
                        taxons.filter(function (obj) {
                            if (selectedTaxons.includes(obj.nuesp.toString())) {
                                result.push(obj);
                            }
                            return true;
                        });
                        this.setState({
                            resultTaxon: result,
                            singleQuestion: false
                        })
                    })
            }
            this.setState({
                isFinished: true
            });
            this.goTo(null, 'pageResponse')
        } else {
            let bestScore = Math.abs((dataMatrix.length) / 2);
            let currentBestScore = 1000;
            let currentBestQuestion;
            let count, j, h;
            let nbUn, nbDeux;

            loop1:
                for (j = 0; j < dataMatrix[0].length; j++) {
                    count = 0;
                    nbUn = 0;
                    nbDeux = 0;
                    function allEqual(arr) {
                        return new Set(arr).size == 1; 
                    }
                    var tm = this.transpose(dataMatrix)
                    for (h = 0; h < dataMatrix.length; h++) {
                        if (dataMatrix[h][j] === '3') {
                            continue loop1;
                        }
                        if (dataMatrix[h][j] === '0') {
                            count = count + 1;
                        }
                        if (dataMatrix[h][j] === '1') {
                            nbUn = nbUn + 1;
                        }
                        if (dataMatrix[h][j] === '2') {
                            nbDeux = nbDeux + 1;
                        }
                    }
                    if (Math.abs(nbUn - count) + nbDeux < currentBestScore && !allEqual(tm[j])) {
                        currentBestScore = Math.abs(nbUn - count) + nbDeux;
                        currentBestQuestion = j;
                    }
                }

            let currentQuestion = getQuestions[currentBestQuestion];
            let currentQuestionImage = getQuestionImages[currentBestQuestion];
            let currentQuestionValue = this.clone(currentBestQuestion);

            this.setState({
                currentQuestion: currentQuestion,
                currentQuestionImage: currentQuestionImage,
                currentQuestionValue: currentQuestionValue
            })
        }

        this.setState({
            dataMatrix: dataMatrix,
            getQuestions: getQuestions,
            getQuestionImages: getQuestionImages,
            getTaxons: getTaxons
        });
    }

    selectFirstQuestion() {
        fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/data.csv")
            .then(r => r.text())
            .then(csv => {
                let dataMatrix = [];
                let getTaxons = [];
                let getQuestions = [];
                let getQuestionImages = [];
                let newGetTaxons = [];
                //let nTaxons;
                let currentQuestion;
                let currentQuestionImage;
                let currentQuestionValue;
                let qCount = this.state.selection.length;

                let that = this;
                let rows = csv.split(/\r\n|\n/);
                rows.forEach(function (row) {
                    dataMatrix.push(row.split(','));
                });
                this.setState({
                    data: dataMatrix
                });
                fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/taxons.json")
                    .then(s => s.json())
                    .then(taxons => {
                        taxons.sort((a, b) => {
                            if (a.name < b.name) return -1;
                            else if (a.name > b.name) return 1;
                            else return 0;
                        });
                        dataMatrix.forEach(function (q) {
                            q.splice(0, 1);
                            getTaxons.push(q.splice(0, 1));
                        });
                        getTaxons.splice(0, 1);

                        let i = 0;
                        getTaxons.forEach(function (taxon) {
                            newGetTaxons.push([i, taxon]);
                            i++;
                        });
                        getTaxons = newGetTaxons;

                        fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/questions.json")
                            .then(t => t.json())
                            .then(questions => {
                                let questionForm = questions;

                                dataMatrix.shift();
                                for (let j = 0; j < qCount; j++) {
                                    getQuestions.push('');
                                }
                                questionForm.forEach(function (obj) {
                                    getQuestions.push(eval('obj.' + that.state.locale));
                                });

                                questionForm.forEach(function (obj) {
                                    if (obj.img !== "") {
                                        getQuestionImages.push("https://lepus.infofauna.ch/taxonomic-key-web/data/" + that.state.groupe + '/q_images/' + obj.img);
                                    } else {
                                        getQuestionImages.push(null);
                                    }
                                });

                                //nTaxons = getTaxons.length;

                                let bestScore = Math.abs((dataMatrix.length) / 2);
                                let currentBestScore = 1000;
                                let currentBestQuestion;
                                let count, j, h;
                                let nbUn, nbDeux;

                                loop1:
                                    for (j = 0; j < dataMatrix[0].length; j++) {
                                        count = 0;
                                        nbUn = 0;
                                        nbDeux = 0;
                                        function allEqual(arr) {
                                            return new Set(arr).size == 1; 
                                        }
                                        var tm = this.transpose(dataMatrix)
                                        for (h = 0; h < dataMatrix.length; h++) {
                                            if (dataMatrix[h][j] === '3') {
                                                continue loop1;
                                            }
                                            if (dataMatrix[h][j] === '0') {
                                                count = count + 1;
                                            }
                                            if (dataMatrix[h][j] === '1') {
                                                nbUn = nbUn + 1;
                                            }
                                            if (dataMatrix[h][j] === '2') {
                                                nbDeux = nbDeux + 1;
                                            }
                                        }
                                        if (Math.abs(nbUn - count) + nbDeux < currentBestScore && !allEqual(tm[j])) {
                                            currentBestScore = Math.abs(nbUn - count) + nbDeux;
                                            currentBestQuestion = j;
                                        }
                                    }

                                currentQuestion = getQuestions[currentBestQuestion];
                                currentQuestionImage = getQuestionImages[currentBestQuestion];
                                currentQuestionValue = this.clone(currentBestQuestion);

                                this.setState({
                                    currentQuestion: currentQuestion,
                                    currentQuestionImage: currentQuestionImage,
                                    currentQuestionValue: currentQuestionValue,
                                    getTaxons: getTaxons,
                                    dataMatrix: dataMatrix,
                                    getQuestions: getQuestions,
                                    getQuestionImages: getQuestionImages,
                                    questionForm: questionForm
                                })
                            })
                    });
            });
    }

    selectQuestion(e, i) {
        let index = i;
        fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/data.csv")
            .then(r => r.text())
            .then(csv => {
                let dataMatrix = [];
                let getTaxons = [];
                let getQuestions = [];
                let getQuestionImages = [];
                let newGetTaxons = [];
                let nTaxons;
                let currentQuestion;
                let currentQuestionImage;
                let currentQuestionValue;
                let qCount = this.state.selection.length;

                let that = this;
                let rows = csv.split(/\r\n|\n/);
                rows.forEach(function (row) {
                    dataMatrix.push(row.split(','));
                });
                this.setState({
                    data: dataMatrix
                });
                fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/taxons.json")
                    .then(s => s.json())
                    .then(() => {

                        dataMatrix.forEach(function (q) {
                            q.splice(0, 1);
                            getTaxons.push(q.splice(0, 1));
                        });
                        getTaxons.splice(0, 1);

                        let i = 0;
                        getTaxons.forEach(function (taxon) {
                            newGetTaxons.push([i, taxon]);
                            i++;
                        });
                        getTaxons = newGetTaxons;

                        fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/questions.json")
                            .then(t => t.json())
                            .then(questions => {
                                let questionForm = questions;

                                dataMatrix.shift();
                                for (let j = 0; j < qCount; j++) {
                                    getQuestions.push('');
                                }
                                questionForm.forEach(function (obj) {
                                    getQuestions.push(eval('obj.' + that.state.locale));
                                });

                                questionForm.forEach(function (obj) {
                                    if (obj.img !== "") {
                                        getQuestionImages.push("https://lepus.infofauna.ch/taxonomic-key-web/data/" + that.state.groupe + '/q_images/' + obj.img);
                                    } else {
                                        getQuestionImages.push(null);
                                    }
                                });

                                let a = that.state.selection;
                                a[index] = true;

                                let c = 0;
                                a.forEach(function (obj) {
                                    if (obj === true) {
                                        c++;
                                    }
                                });

                                let m = [];
                                for (let f = 0; f < dataMatrix.length; f++) {
                                    m.push(0);
                                }

                                for (let g = 0; g < a.length; g++) {
                                    for (let h = 0; h < dataMatrix.length; h++) {
                                        if ((dataMatrix[h][0] === "1" && a[g] === true) || (dataMatrix[h][0] === "2" && a[g] === true)) {
                                            m[h] = m[h] + 1;
                                        }
                                        dataMatrix[h].splice(0, 1);
                                    }
                                    getQuestions.splice(0, 1);
                                }

                                let i = m.length;
                                while (i--) {
                                    if (m[i] !== c) {
                                        dataMatrix.splice(i, 1);
                                        getTaxons.splice(i, 1);
                                    }
                                }

                                nTaxons = getTaxons.length;
                                if (nTaxons === 1) {
                                    fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/taxons.json")
                                        .then(s => s.json())
                                        .then(taxons => {
                                            taxons = taxons.sort((a, b) => {
                                                if (a.name < b.name) return -1;
                                                else if (a.name > b.name) return 1;
                                                else return 0;
                                            });
                                            let result;
                                            taxons.forEach(function (obj) {
                                                if (obj.nuesp == getTaxons[0][1][0]) {
                                                    result = obj;
                                                }
                                            });
                                            result["description_fr"] = result["description_fr"].replace(new RegExp('<source src="', 'g'), '<source src="https://lepus.infofauna.ch/taxonomic-key-web/data/' + this.state.groupe + '/r_images/');
                                            result["description_de"] = result["description_de"].replace(new RegExp('<source src="', 'g'), '<source src="https://lepus.infofauna.ch/taxonomic-key-web/data/' + this.state.groupe + '/r_images/');
                                            result["description_it"] = result["description_it"].replace(new RegExp('<source src="', 'g'), '<source src="https://lepus.infofauna.ch/taxonomic-key-web/data/' + this.state.groupe + '/r_images/');
                                            let images = [];
                                            if (result['leadimg']) {
                                                images.push("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/r_images/' + result['leadimg'][0].filename);
                                            }
                                            result['imgs'].forEach(img => {
                                                images.push("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/r_images/' + img.filename);
                                            });
                                            this.setState({
                                                resultTaxon: result,
                                                images: images,
                                                singleQuestion: false,
                                                getTaxons: getTaxons,
                                                isFinished: true
                                            });
                                            this.goTo(null, 'singleResponse');
                                        })
                                } else {
                                    let bestScore = Math.abs((dataMatrix.length) / 2);
                                    let currentBestScore = 1000;
                                    let currentBestQuestion;
                                    let count, j, h;
                                    let nbUn, nbDeux;

                                    loop1:
                                        for (j = 0; j < dataMatrix[0].length; j++) {
                                            count = 0;
                                            nbUn = 0;
                                            nbDeux = 0;
                                            function allEqual(arr) {
                                                return new Set(arr).size == 1; 
                                            }
                                            var tm = this.transpose(dataMatrix)
                                            for (h = 0; h < dataMatrix.length; h++) {
                                                if (dataMatrix[h][j] === '3') {
                                                    continue loop1;
                                                }
                                                if (dataMatrix[h][j] === '0') {
                                                    count = count + 1;
                                                }
                                                if (dataMatrix[h][j] === '1') {
                                                    nbUn = nbUn + 1;
                                                }
                                                if (dataMatrix[h][j] === '2') {
                                                    nbDeux = nbDeux + 1;
                                                }
                                            }
                                            if (Math.abs(nbUn - count) + nbDeux < currentBestScore && !allEqual(tm[j])) {
                                                currentBestScore = Math.abs(nbUn - count) + nbDeux;
                                                currentBestQuestion = j;
                                            }
                                        }

                                    currentQuestion = getQuestions[currentBestQuestion];
                                    currentQuestionImage = getQuestionImages[currentBestQuestion];
                                    currentQuestionValue = this.clone(currentBestQuestion);

                                    this.setState({
                                        currentQuestion: currentQuestion,
                                        currentQuestionImage: currentQuestionImage,
                                        currentQuestionValue: currentQuestionValue,
                                        getTaxons: getTaxons,
                                        dataMatrix: dataMatrix,
                                        getQuestions: getQuestions,
                                        getQuestionImages: getQuestionImages,
                                        questionForm: questionForm
                                    });
                                }


                            })
                    });
            });
        this.goTo(null, 'singleQuestion');
    }

    htmlResult(text) {
        return {__html: text};
    }

    openLighbox(image) {
        this.setState({
            isOpen: true,
            photoIndex: this.state.images.indexOf(image)
        })
    }

    goTo(e, subpage) {
        let pageOne;
        if (this.state.groupe === 'moll' && (subpage === 'introtext' || subpage === 'groupText')) {
            pageOne = 'groupText'
        } else if (this.state.groupe === 'amphipoda' && (subpage === 'introtext' || subpage === 'groupText')) {
          pageOne = 'groupText'
        } else if ((this.state.groupe !== 'moll' && this.state.groupe !== 'amphipoda') && (subpage === 'introtext' || subpage === 'groupText')) {
            pageOne = 'introText'
        }
        this.setState({
            introText: pageOne === 'introText',
            groupText: pageOne === 'groupText',
            multiQuestions: subpage === 'multiQuestions',
            singleQuestion: subpage === 'singleQuestion',
            speciesList: subpage === 'speciesList',
            pageResponse: subpage === 'pageResponse'
        })
    }

    repeatGroup() {
        fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/infos.json")
            .then(r => r.json())
            .then(d => {
                let arr = new Array(d.multi_names_fr.length);
                arr.fill(false, 0, d.multi_names_fr.length);

                this.setState({
                    groupInfo: d,
                    singleQuestion: (d.multi === 'none'),
                    openClass: 'panel panel-close',
                    panelDisabled: false,
                    selection: arr,
                    data: null,
                    currentQuestion: null,
                    currentQuestionImage: null,
                    currentQuestionValue: null,
                    getTaxons: null,
                    getQuestions: null,
                    dataMatrix: null,
                    getQuestionImages: null,
                    currentBestQuestion: null,
                    questionForm: null,
                    isFinished: false,
                    resultTaxon: null
                });

                if (d.multi === 'none') {
                    this.selectFirstQuestion();
                    this.goTo(null, 'singleQuestion')
                } else {
                    this.goTo(null, 'multiQuestions')
                }
            })
    }

    setModalShow(e, b, i) {
        if (b) {
            this.setState({
                modalShow: b,
                modalData: this.state.resultTaxon[i]
            })
        } else {
            this.setState({
                modalShow: b
            })
        }
    }

    setModalTaxonShow(e, b, i) {
        this.setState({
            modalShow: b,
            modalData: this.state.taxonList[i]
        })
    }

    render() {
        const {photoIndex, isOpen, images, modalShow} = this.state;

        return (
            <div className="jumbotron jumbotron-mod">
                <div hidden={!this.state.introText} className="big-left-margin"
                     dangerouslySetInnerHTML={this.htmlResult(this.state.mainText)}/>
                <div hidden={(!this.state.groupText || this.state.groupe !== 'moll')} className="title-page-margin">
                    <div>
                        <img src={nmbe} alt="Logo NMBE" className="logo-big"/>
                        <h2>Bestimmungsschlüssel Mollusken Schweiz</h2>
                        <h3>Autorin MSc Estée Bochud<br/>
                            Technik & Support CSCF<br/>
                            2019</h3>
                        <button className="btn btn-primary btn-jumbotron btn-bottom-right"
                                onClick={this.repeatGroup}>{textes.commencercle[this.state.locale]}
                        </button>
                        <button className="btn btn-primary btn-jumbotron btn-bottom-left"
                                onClick={(e) => this.goTo(e, 'speciesList')}>{textes.listefiches[this.state.locale]}
                        </button>
                    </div>
                </div>
              <div hidden={!this.state.groupText || this.state.groupe !== 'amphipoda'} className="title-page-margin">
                <div>
                  <img src={eawag} alt="Logo EAWAG + UNIZH" className="logo-big"/>
                  <h2>Bestimmungsschlüssel Amphipoden Schweiz</h2>
                  <h3>Autoren: Roman Alther, Cene Fišer, Vid Švara, Florian Altermatt<br/>
                    Technik & Support: CSCF<br/>
                    2020</h3>
                  <button className="btn btn-primary btn-jumbotron btn-bottom-right"
                          onClick={this.repeatGroup}>{textes.commencercle[this.state.locale]}
                  </button>
                  <button className="btn btn-primary btn-jumbotron btn-bottom-left"
                          onClick={(e) => this.goTo(e, 'speciesList')}>{textes.listefiches[this.state.locale]}
                  </button>
                </div>
              </div>
                <div
                    hidden={!this.state.multiQuestions}>
                    <p
                        className="question-title">{this.state.groupInfo ? this.state.groupInfo['multi_text_' + this.state.locale] : ''}</p>
                    {
                        this.state.groupInfo ? this.state.groupInfo['multi_names_' + this.state.locale].map((qg, i) => {
                            let imgSrc = "https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/icons/" + this.state.groupInfo['multi_logos'][i];
                            return (
                                <button key={i.toString()} className="list-group-item list-group-item-action"
                                        onClick={(e) => this.selectQuestion(e, i)}>
                                    <img src={imgSrc} className="small-icon-img" alt={qg}/>{qg}</button>
                            )
                        }) : ''
                    }
                </div>
                <div hidden={!this.state.speciesList}>
                    <h2>{textes.listefiches[this.state.locale]}</h2>
                    {
                        this.state.taxonList && this.state.taxonList.map((taxon, i) => {
                            return (
                                <div>
                                    { i === 0 && <p className="question-title" dangerouslySetInnerHTML={this.htmlResult(eval("taxon.diffs" + this.state.locale))}/>}
                                    <button key={i.toString()} className="list-group-item list-group-item-action"
                                            onClick={(e) => this.setModalTaxonShow(e, true, i)}>
                                        <em dangerouslySetInnerHTML={this.htmlResult(taxon.name)}/><br/><small
                                        dangerouslySetInnerHTML={this.htmlResult(taxon[this.state.locale])}/><br/>
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
                <div hidden={!this.state.singleQuestion}>
                    <p className="question-title">{textes['question'][this.state.locale]}</p>
                    <p className="main-question" dangerouslySetInnerHTML={this.htmlResult(this.state.currentQuestion)}/>
                    <img src={this.state.currentQuestionImage} className="img img-fluid img-jumbotron"
                         alt={this.state.currentQuestion}/>
                    <div className="btn-jumbotron-group">
                        <button className="btn btn-primary btn-jumbotron"
                                onClick={(e) => this.selectNextQuestion(e, 1)}>{textes['oui'][this.state.locale]}
                        </button>
                        &nbsp;&nbsp;
                        <button className="btn btn-primary btn-jumbotron"
                                onClick={(e) => this.selectNextQuestion(e, 0)}>{textes['non'][this.state.locale]}
                        </button>
                        &nbsp;&nbsp;
                        <button className="btn btn-primary btn-jumbotron"
                                onClick={(e) => this.selectNextQuestion(e, 3)}>{textes['nonvisible'][this.state.locale]}
                        </button>
                    </div>
                </div>
                <div hidden={!this.state.pageResponse} className="jumbotron-inside">
                    {
                        this.state.resultTaxon && this.state.getTaxons.length === 1 &&
                        <div>
                            {this.state.resultTaxon['leadimg'] &&
                            <img className="img-right img-clickable"
                                 onClick={() => this.openLighbox("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/r_images/' + this.state.resultTaxon['leadimg'][0]['filename'])}
                                 src={this.state.resultTaxon ? "https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/r_images/' + this.state.resultTaxon['leadimg'][0]['filename'] : null}
                                 alt={this.state.resultTaxon ? this.state.resultTaxon['leadimg'][0]['legende_' + this.state.locale] : ''}/>
                            }
                            <p className="main-question"
                               dangerouslySetInnerHTML={this.state.resultTaxon ? this.htmlResult(this.state.resultTaxon['name']) : null}/>
                            <p><em
                                className="really-small">{this.state.resultTaxon ? this.state.resultTaxon['auteur_annee'] : ''}</em>
                            </p>
                            <p>
                                FR: <span
                                dangerouslySetInnerHTML={this.state.resultTaxon ? this.htmlResult(this.state.resultTaxon['fr']) : null}/><br/>
                                DE: <span
                                dangerouslySetInnerHTML={this.state.resultTaxon ? this.htmlResult(this.state.resultTaxon['de']) : null}/><br/>
                                IT: <span
                                dangerouslySetInnerHTML={this.state.resultTaxon ? this.htmlResult(this.state.resultTaxon['it']) : null}/>
                            </p>
                            <p
                                dangerouslySetInnerHTML={this.state.resultTaxon ? this.htmlResult(this.state.resultTaxon['description_' + this.state.locale]) : null}/>
                            {
                                this.state.resultTaxon ? this.state.resultTaxon['imgs'].map((img, i) => {
                                    return (
                                        <div className="img-small" key={i}>
                                            <img
                                                onClick={() => this.openLighbox("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/r_images/' + img['filename'])}
                                                src={"https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/r_images/' + img['filename']}
                                                className="img-fluid img-clickable"
                                                alt={img['legende_' + this.state.locale]}/>
                                            <p dangerouslySetInnerHTML={this.htmlResult(img['legende_' + this.state.locale])}/>
                                        </div>
                                    )
                                }) : ''
                            }
                        </div>
                    }
                    {
                        this.state.resultTaxon && this.state.getTaxons.length > 1 &&
                        <div>
                            <p
                                className="question-title">{textes['critNonDet'][this.state.locale]}
                            </p>
                            {
                                this.state.resultTaxon ? this.state.resultTaxon.map((taxon, i) => {
                                    return (
                                      <div>
                                        { i === 0 && <p className="question-title" dangerouslySetInnerHTML={this.htmlResult(eval("taxon.diffs" + this.state.locale))}/>}
                                        <button key={i.toString()} className="list-group-item list-group-item-action"
                                                onClick={(e) => this.setModalShow(e, true, i)}>
                                            <em dangerouslySetInnerHTML={this.htmlResult(taxon.name)}/><br/><small
                                            dangerouslySetInnerHTML={this.htmlResult(taxon[this.state.locale])}/><br/>
                                          {taxon.diffs &&
                                          <img className="img-small-list" src={"https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/q_images/' + taxon.diffs} alt={taxon.name + " image"}/>
                                          }
                                        </button>
                                      </div>
                                    )
                                }) : ''
                            }
                        </div>
                    }
                </div>
                <div hidden={!this.state.modalOpen}>
                    <Button variant="primary" onClick={() => this.setModalShow(true)}>
                        Launch vertically centered modal
                    </Button>

                    {
                        this.state.modalData &&
                        <Modal
                            show={modalShow}
                            onHide={() => this.setModalShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="main-question"
                                   dangerouslySetInnerHTML={this.state.modalData ? this.htmlResult(this.state.modalData['name']) : null}/>
                                <p><em
                                    className="really-small">{this.state.modalData ? this.state.modalData['auteur_annee'] : ''}</em>
                                </p>
                                <p>
                                    FR: <span
                                    dangerouslySetInnerHTML={this.state.modalData ? this.htmlResult(this.state.modalData['fr']) : null}/><br/>
                                    DE: <span
                                    dangerouslySetInnerHTML={this.state.modalData ? this.htmlResult(this.state.modalData['de']) : null}/><br/>
                                    IT: <span
                                    dangerouslySetInnerHTML={this.state.modalData ? this.htmlResult(this.state.modalData['it']) : null}/>
                                </p>
                              {this.state.modalData['leadimg'] &&

                              <img className="img-fluid"
                                   src={this.state.modalData ? "https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/r_images/' + this.state.modalData['leadimg'][0]['filename'] : ''}
                                   alt={this.state.modalData['leadimg'][0]['filename']}
                              />
                              }
                                <p
                                    dangerouslySetInnerHTML={this.state.modalData ? this.htmlResult(this.state.modalData['description_' + this.state.locale]) : null}/>
                                {
                                    this.state.modalData ? this.state.modalData['imgs'].map((img, i) => {
                                        return (
                                            <div className="img-small" key={i}>
                                                <img
                                                    src={"https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + '/r_images/' + img['filename']}
                                                    className="img-fluid"
                                                    alt={img['legende_' + this.state.locale]}/>
                                                <p dangerouslySetInnerHTML={this.htmlResult(img['legende_' + this.state.locale])}/>
                                            </div>
                                        )
                                    }) : ''
                                }
                            </Modal.Body>
                        </Modal>
                    }
                </div>
                <div className="footer-outside-align" hidden={!(this.state.singleQuestion || this.state.pageResponse)}>
                    <button className="btn btn-secondary"
                            onClick={this.repeatGroup}>{textes['recommencer'][this.state.locale]}</button>
                </div>
                <div className="footer-outside-align" hidden={!this.state.speciesList}>
                    <button className="btn btn-secondary"
                            onClick={(e) => this.goTo(e, 'groupText')}>{textes.aLaCle[this.state.locale]}</button>
                </div>
                <Lightbox
                    open={images!=null && isOpen}
                    index={photoIndex}
                    close={() => this.setState({isOpen: false})}
                    slides={images!=null && images.map((image) => ({ src: image }))}
                    styles={{ container: { backgroundColor: "rgba(0, 0, 0, .85)" } }}    
                    plugins={[Zoom]}
                    zoom={{ scrollToZoom: true }}          
                />
            </div>
        )
    }
}

export default QuestionPanel;
