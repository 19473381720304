import React, {Component} from 'react';
import './AnatomiePanel.css';
import {ThreeBarsIcon, XIcon} from '@primer/octicons-react';

const textes = {
  'anatomie': {
    'fr': 'Anatomie',
    'it': 'Anatomia',
    'de': 'Anatomie'
  }
};

let convertHTML = function(h, g) {
  let parser = new DOMParser();
  let html = parser.parseFromString(h, 'text/html');
  let baseEl = html.createElement('base');
  baseEl.setAttribute('href', "https://lepus.infofauna.ch/taxonomic-key-web/data/" + g + "/htmls/anatomie/");
  html.head.append(baseEl);
  html.querySelectorAll('img')
    .forEach(element => {
      element.src = String(element.src)
    });
  return html.body.innerHTML;
};

class AnatomiePanel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      openClass: 'panel panel-close',
      iconClass: 'panel-icon',
      anatomieClass : 'anatomie-html-close',
      panelHidden: true,
      panelDisabled: true,
      data: null,
      isLoading: false,
      error: null,
      locale: props.locale,
      groupe: props.groupe
    };
    if(props.groupe) {
      fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + props.groupe + "/htmls/anatomie/" + props.locale + ".html")
          .then(r=>r.text())
          .then(d=>{
            this.setState({
              data: convertHTML(d, props.groupe),
              openClass: 'panel panel-close',
              anatomieClass : 'anatomie-html-close',
              panelDisabled: false
            })
          });
    }

    this.openPanel = this.openPanel.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  openPanel(e) {
    this.setState({
      openClass: 'panel',
      anatomieClass : 'anatomie-html',
      panelHidden: !this.state.panelHidden
    })
  }

  closePanel(e) {
    this.setState({
      openClass: 'panel panel-close',
      anatomieClass : 'anatomie-html-close',
      panelHidden: !this.state.panelHidden
    })
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {

    if (this.props.groupe !== nextProps.groupe) {
      this.setState({
        groupe: nextProps.groupe,
        openClass: 'panel panel-close',
        anatomieClass : 'anatomie-html-close',
        panelDisabled: false,
      });

      fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + nextProps.groupe + "/htmls/anatomie/" + this.state.locale + ".html")
        .then(r=>r.text())
        .then(d=>{
          this.setState({data: convertHTML(d, nextProps.groupe)})
        });
    }

    if (this.props.locale !== nextProps.locale) {
      this.setState({
        locale: nextProps.locale
      });

      fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/htmls/anatomie/" + nextProps.locale + ".html")
        .then(r=>r.text())
        .then(d=>{
          this.setState({data: convertHTML(d, this.state.groupe)})
        });
    }

    return true;
  }

  getHtmlLang() {
      return {__html: this.state.data};
  }

  render() {
    return (
      <div className={this.state.openClass} disabled={this.state.panelDisabled}>
        <button onClick={this.openPanel} className="button-link panel-open-handle" hidden={!this.state.panelHidden}>
          <ThreeBarsIcon size='medium' verticalAlign='middle' className={this.state.iconClass}/>
          <h2 className="panel-title">{textes['anatomie'][this.state.locale]}</h2>
        </button>
        <button hidden={this.state.panelHidden} onClick={this.closePanel} className="button-link panel-close-handle">
          <XIcon size='medium' verticalAlign='middle' className="panel-icon"/>
        </button>
        <div dangerouslySetInnerHTML={this.getHtmlLang()} className={this.state.anatomieClass}/>
      </div>
    )
  }
}

export default AnatomiePanel;
