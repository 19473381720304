import React, {Component} from 'react';
import {ThreeBarsIcon, TriangleRightIcon} from '@primer/octicons-react';
import './LinkerComponent.css';

const textes = {
  "distribution": {
    "fr": "Cartes de distribution",
    "de": "Verbreitungskarten",
    "it": "Server cartografico",
    "en": "Map server"
  },
  "tabulaire": {
    "fr": "Listes d'espèces",
    "de": "Artenliste",
    "it": "Server tabelle",
    "en": "Table server"
  },
  "determination": {
    "fr": "Clés de détermination",
    "de": "Bestimmungsschlüssel",
    "it": "Chiavi di determinazione",
    "en": "Identification keys"
  }
};

class LinkerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false,
      locale: props.locale
    };

    this.dropdownShow = this.dropdownShow.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.locale !== this.state.locale) {
      this.setState({
        locale: nextProps.locale
      })
    }

    return true;
  }

  dropdownShow() {
    this.setState({
      showDropdown: !this.state.showDropdown
    })
  }

  render() {

    const {locale, showDropdown} = this.state;

    return (
      <div>
        <div className="linker-component">
          <button className="btn icon-only shadow-none" onClick={this.dropdownShow}>
            <ThreeBarsIcon size="medium"/>
          </button>
        </div>
        <div className="dd" hidden={!showDropdown}>
          <TriangleRightIcon size="small" className="icon-black"/>&nbsp;&nbsp;<a
          href="https://lepus.infofauna.ch/carto/">{textes.distribution[locale]}</a><br/>
          <TriangleRightIcon size="small" className="icon-black"/>&nbsp;&nbsp;<a
          href="https://lepus.infofauna.ch/infofauna-key/" className="disabled">{textes.determination[locale]}</a><br/>
          <TriangleRightIcon size="small" className="icon-black"/>&nbsp;&nbsp;<a
          href="https://lepus.infofauna.ch/tab/">{textes.tabulaire[locale]}</a>
        </div>
      </div>
    )
  }
}

export default LinkerComponent
