import React from 'react';
import logo from './assets/images/IF_CSCF_gris_sans.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import LangPanel from "./components/langPanel/LangPanel";
import GroupPanel from "./components/groupPanel/GroupPanel";
import FooterPanel from "./components/footerPanel/FooterPanel";
import QuestionPanel from "./components/questionPanel/QuestionPanel";
import AnatomiePanel from "./components/anatomiePanel/AnatomiePanel";

const textes = {
  'titre': {
    'fr': 'Centre national\n' +
      'de données et d\'informations\n' +
      'sur la faune de Suisse',
    'de': 'Schweizerisches\n' +
      'Informationszentrum\n' +
      'für die Fauna',
    'it': 'Centro svizzero\n' +
      'd\'informazione\n' +
      'sulle specie',
    'en': 'Swiss topic\n' +
      'center on fauna'
  }
};

class App extends React.Component {

  constructor(props) {
    super(props);

    let locale;

    if (localStorage.getItem('if.key.lang') === null) {
      if (navigator.language.indexOf(('fr', 'de', 'it')) > -1) {
        locale = navigator.language.substring(0, 2);
      } else {
        locale = 'fr';
      }
    } else {
      locale = localStorage.getItem('if.key.lang');
    }

    this.state = {
      locale: locale,
      groupe: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
      showDropdown: false
    };
  }

  langChangeCallback = (lang) => {
    this.setState({locale: lang})
  };

  groupeChange = (group) => {
    this.setState({groupe: group});
  };

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div className="App-header-item"><img src={logo} alt="Logo info fauna"
                                                className="App-header-logo"/>
          </div>
          <div className="App-header-item App-h2">
            <span className="App-h1">info fauna</span><br/>
            {textes.titre[this.state.locale]}
          </div>
          <div className="end">
            <LangPanel locale={this.state.locale} callbackFromParent={this.langChangeCallback}/>
          </div>
        </header>
        <main className="App-article">
          <GroupPanel locale={this.state.locale} groupe={this.state.groupe} callbackFromParent={this.groupeChange} />
          <AnatomiePanel locale={this.state.locale} groupe={this.state.groupe}/>
          <QuestionPanel locale={this.state.locale} groupe={this.state.groupe}/>
          <FooterPanel locale={this.state.locale} groupe={this.state.groupe} />
        </main>
      </div>
    );
  }
}

export default App;
