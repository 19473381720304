import React, {Component} from 'react';
import './FooterPanel.css';
import {XIcon} from '@primer/octicons-react';
import packageJson from '../../../package.json';

const { version } = packageJson;

let convertHTML = function(h, g) {
  let parser = new DOMParser();
  let html = parser.parseFromString(h, 'text/html');
  let baseEl = html.createElement('base');
  baseEl.setAttribute('href', "https://lepus.infofauna.ch/taxonomic-key-web/data/" + g + "/htmls/anatomie/");
  html.head.append(baseEl);
  html.querySelectorAll('img')
    .forEach(element => {
      element.src = String(element.src)
    });
  return html.body.innerHTML;
};

class FooterPanel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      locale: props.locale,
      groupe: props.groupe,
      panelOpen: false,
      panelClass: 'footer-panel footer-panel-close'
    };
    if(props.groupe) {
      fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + props.groupe + "/htmls/impressum/" + props.locale + ".html")
          .then(r=>r.text())
          .then(d=>{
            this.setState({
              data: convertHTML(d, props.groupe)
            })
          });
    }

    this.openImpressum = this.openImpressum.bind(this);
    this.closePanel = this.closePanel.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {

    if (this.props.groupe !== nextProps.groupe) {
      this.setState({
        groupe: nextProps.groupe,
        openClass: 'panel panel-close',
        panelDisabled: false
      });

      fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + nextProps.groupe + "/htmls/impressum/" + this.state.locale + ".html")
        .then(r=>r.text())
        .then(d=>{
          this.setState({data: convertHTML(d, nextProps.groupe)})
        });
    }

    if (this.props.locale !== nextProps.locale) {
      this.setState({
        locale: nextProps.locale
      });

      fetch("https://lepus.infofauna.ch/taxonomic-key-web/data/" + this.state.groupe + "/htmls/impressum/" + nextProps.locale + ".html")
        .then(r=>r.text())
        .then(d=>{
          this.setState({data: convertHTML(d, this.state.groupe)})
        });
    }

    return true;
  }

  openImpressum() {
    this.setState({
      panelOpen: !this.state.panelOpen,
      panelClass: 'footer-panel'
    })
  }

  closePanel() {
    this.setState({
      panelOpen: !this.state.panelOpen,
      panelClass: 'footer-panel footer-panel-close'
    })
  };

  getHtmlLang() {
    return {__html: this.state.data};
  }

  render() {
    return(
      <div className={this.state.panelClass}>
        <div className="footer-left-align" hidden={this.state.panelOpen}>
          <button className="button-link" onClick={this.openImpressum} hidden={!this.state.groupe}>Impressum</button>
        </div>
        <div className="footer-right-align" hidden={this.state.panelOpen}>
          &copy; <a href="https://www.infofauna.ch">info fauna</a>
        </div>
        <div className="footer-version" hidden={this.state.panelOpen}><small>v{version}</small></div>
        <button hidden={!this.state.panelOpen} onClick={this.closePanel}
           className="button-link left-panel-close-handle">
          <XIcon size='medium' verticalAlign='middle' className="left-panel-icon"/>
        </button>
        <div hidden={!this.state.panelOpen}>
          <h2>Impressum</h2>
          <div dangerouslySetInnerHTML={this.getHtmlLang()} />
        </div>
      </div>
    )
  }
}

export default FooterPanel;
